<template lang="">
  <div class="border shadow rounded-3 p-5">
    <p class="text-uppercase text-xs font-weight-bold mb-2 color-gray-60">
      {{ $t('Welcome To') }}
    </p>
    <h1 class="text-xxl text-gray-80">
      {{ $t('Training Center') }}
    </h1>
    <div>
      <p class="text-gray-500 font-weight-bold font-size-20">
        {{ $t('Login to continue') }}
      </p>
      <form @submit.prevent="initCaptcha">
        <div class="mb-3">
          <rd-input
            refs="username"
            :placeholder="$t('Username')" 
            type="text"
            :value="username"
            :label="$t('Enter your username')"
            @input="(e) => handleInput('username', e)"
          />
        </div>
        <div class="mb-3">
          <rd-input
            refs="password"
            :placeholder="$t('Password')"
            type="password"
            showPassIcon
            :value="password"
            :label="$t('Enter your password')"
            @input="(e) => handleInput('password', e)"
            :autocomplete="true"
          />
        </div>
        <vue-recaptcha
          :load-recaptcha-script="true"
          :sitekey="siteKey"
          size="invisible"
          language="en"
          @verify="recaptchaVerified"
          ref="recaptcha"
        />
        <div v-if="invalidCredentials" class="mb-3 text-red">
          <h6>{{ $t('Invalid credentials') }}</h6>
        </div>
        <button 
          type="submit" 
          class="btn btn-primary border w-100"
          :disabled="username === '' || password === ''"
        >
          {{ $t('Login (btn)') }}
        </button>
      </form>
      
    </div>
    <div class="text-footer">Version 3.1.9.7 </div>
  </div>

</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
import RdInput from '@/components/rd-components/rd-input.vue'
import { mapActions } from 'vuex';
import { getShoulShowChat } from '@/services/user/user.api.js'
import { initialize } from '@/utils/chat.js'
import { mapState } from 'vuex'

export default {
  name: "login-form",
  components: {
    RdInput,
    VueRecaptcha
  },
  async created() {
    let authToken = this.$route.query.authToken;
    if (!authToken) return;
    let result = await this.loginByToken({ mainAuthToken: authToken});
    if (result) {
      this.handleRouting()
    }
  },
  data() {
    return {
      username: '',
      password: '',
      invalidCredentials: false
    };
  },
  computed: {
    siteKey() {      
      return process.env.VUE_APP_CAPTCHAKEY;
    },
    user() {
      return this.$store.state.user;
    },
    ...mapState([
      'isMobile',
      'settings'
    ]),
  },
  methods: {
    ...mapActions([
      'login',
      'loginByToken'
    ]),
    handleRouting() {
      // if(this.user.isAdmin || this.user.isUploader || this.user.isTrainer) {
      //   this.$router.push('/admin');
      // } else {
      //   this.$router.push('/files');
      // }
      // TODO: Calc route with permissions
      this.$router.push('/admin');
    },
    async handleLogin() {
      try {
        this.invalidCredentials = false;
        const credentials = {
          username: this['username'],
          password: this['password']
        }
        const { isAvailable } = await this.login(credentials);
        if (!isAvailable) {
          this.invalidCredentials = true;
          return;
        }
        const showChat = await getShoulShowChat();
        if(showChat.data) {
          const currentDate = new Date();
          const timestampUnix = Math.floor(currentDate.getTime() / 1000);
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "zi9hkx6m",
            name: `${this.user.userFirstname} ${this.user.userLastname}`,
            user_id: this.user.userId,
            email: this.user.userEmail,
            created_at: timestampUnix,
            rol: this.user.roleName,
            rol_id: this.user.roleId,
            app: 'repodocs',
            project: (this.settings? this.settings.title : null) ||  this.$t('Smart Files')
          };
          initialize({
            api_base: "https://api-iam.intercom.io",
            app_id: "zi9hkx6m",
            name: `${this.user.userFirstname} ${this.user.userLastname}`,
            user_id: this.user.userId,
            email: this.user.userEmail,
            created_at: timestampUnix,
            rol: this.user.roleName,
            rol_id: this.user.roleId,
            app: 'repodocs',
            project: (this.settings? this.settings.title : null) ||  this.$t('Smart Files')
          });
        }
        this.handleRouting();
      } catch (error) {
        this.invalidCredentials = true
        setTimeout(() => { this.invalidCredentials = false }, 3000)
      } finally {
        this.$refs.recaptcha.reset();
      }
    },
    initCaptcha() {
      this.$refs.recaptcha.execute()
    },
    recaptchaVerified() {
      this.handleLogin();
    },
    handleInput(prop, e) {
      this[prop] = e.target.value;
    },
  },
};
</script>
<style scoped>
.text-footer {
  font-size: 0.65rem !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
  padding-top: 1.5rem;
  line-height: 1 !important;
  color: #b6b6b8 !important;
}
</style>
