<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />

      <div v-if="loading" class="my-5 py-5 d-flex align-items-center justify-content-center">
        <rd-loading />
      </div>

      <div v-else class="d-flex flex-column justify-content-center">
        <div class="p-2 mt-2">
          <h1>{{ $t("Assignment Maps") }}</h1>
        </div>

        <div class="d-flex flex-md-row flex-column justify-content-between gap-2 px-4 mt-2" style="gap: 1rem;">
          <rd-input type="checkbox" :value="showSites" v-model="showSites" :checked2="showSites"
            @change="showSites = !showSites" :label="'Show Sites'" />
        </div>
        <div class="d-flex flex-md-row flex-column justify-content-between gap-2 px-4 mt-2" style="gap: 1rem;">
          <rd-input type="checkbox" :value="showUsers" v-model="showUsers" :checked2="showUsers"
            @change="toogleShowUsers" :label="'Show Users'" />
        </div>
        <div class="d-flex flex-md-row flex-column justify-content-between gap-2 px-4 mt-2">
          <rd-input :value="searchSite" v-if="showSites" type="text" :label="$t('Search sites')" :placeholder="$t('Search site by site name or code')"
            @input="(e) => searchVal(e.target.value)" />
          <rd-input :items="allUsers" :show-dropdown="true" :value="searchUsers" v-if="showUsers" type="text" :label="$t('Search users')" :placeholder="$t('Search user by username')"
            @input="(e) => searchValUsers(e.target.value)" />
            <!-- <rd-multiselect
              v-if="showUsers"
              ref="multiselect"
              :initialValue="users"
              :placeholderToTag="$t('Add users to search')"
              :placeholder="$t('Users')"
              :label="$t('Add users to search')"
              name="name"
              :optionsToSelect="allUsers"
              @click="toogleSearch"
              @handleTagChange="changeTag"
            /> -->
        </div>
        <div class="p-2 mt-2" v-if="!reset" style="position: relative;">
          <div @click="showRoute(route)" :class="route.type" v-for="route in distances" :key="route.type">
            <i class="bi bi-car-front-fill" v-if="route.type == 'DRIVING'"></i>
            <i class="bi bi-person-walking" v-else></i>
          </div>
          <div class="map-container" id="map" style=" width: 100%; height: 60vh; position: relative;">
            <GMapMap :center="center" :zoom="10" map-type-id="terrain" style=" width: 100%; height: 60vh;"
              @click="calcClose">
              <GMapCluster :zoomOnClick="true">
                <GMapMarker v-for="m in filteredMarkers" :key="m.id" :position="m.position" :clickable="true"
                  @click="handleClickMarker(m)">
                  <GMapInfoWindow ref="infoWindow" :opened="m.selected">
                    <div class=" mt-4" v-if="selectedSite">
                      <div class="site-info-card">
                        <h4>{{ $t("Site Information") }}</h4>
                        <p><strong>{{ $t("Code") }}:</strong> {{ selectedSite.code }}</p>
                        <p><strong>{{ $t("Name") }}:</strong> {{ m.name }}</p>
                        <p><strong>{{ $t("Direction") }}:</strong> {{ selectedSite.address }}</p>
                        <p>
                          <strong>{{ $t("Team") }}:</strong>
                          <span v-if="selectedSite.team">{{ selectedSite.team.team }}</span>
                          <span v-else>{{ $t("No data found") }}</span>
                        </p>
                        <p>
                          <strong>{{ $t("Dispatcher") }}:</strong>
                          <span v-if="selectedSite.team">{{ selectedSite.team.dispatcher }}</span>
                          <span v-else>{{ $t("No data found") }}</span>
                        </p>
                        <div class="d-flex flex-column align-items-baseline"
                          v-if="selectedSite.team && selectedSite.team.users.length > 0">
                          <strong>{{ $t("Team Users") }}:</strong>
                          <ul class="d-flex flex-column align-items-baseline">
                            <li v-for="user in selectedSite.team.users" :key="user.userId">
                              {{ user.userFirstname }} {{ user.userLastname }} - {{ user.userName }} ({{ user.phone }})
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class=" mt-4" v-if="selectedUser">
                      <div class="site-info-card">
                        <h4>{{ $t("User Information") }}</h4>
                        <p><strong>{{ $t("Username") }}:</strong> {{ selectedUser.name }}</p>
                        <p><strong>{{ $t("Name") }}:</strong> {{ selectedUser.user_first_name }} {{ selectedUser.user_lastname }}</p>
                        <p><strong>{{ $t("Phone") }}:</strong> {{ selectedUser.phone }}</p>
                      </div>
                    </div>
                  </GMapInfoWindow>
                </GMapMarker>
              </GMapCluster>
            </GMapMap>
          </div>
        </div>

      </div>

    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdInput from '../../components/rd-components/rd-input.vue';
import RdLoading from '../../components/rd-components/rd-loading.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import { getAllSites } from '../../services/sites/site.api';
import { getAllLeadOperators } from '../../services/user/user.api';
// import RdMultiselect from "../../components/rd-components/rd-multiselect.vue";

export default {
  components: { RdNavbar, RdFooter, RdInput, RdLoading },
  data() {
    return {
      center: { lat: 0, lng: 0 },
      sites: [],
      searchSite: '',
      searchUsers: '',
      users: [],
      allUsers: [],
      showSites: true,
      showUsers: false,
      loading: true,
      google: null,
      reset: false,
      directionsRenderer: null,
      distances: [],
      last: null,
      selectedSite: null,
      hasClose: false,
      selectedUser: null
    };
  },
  async mounted() {
    await this.getInitialData();

  },
  computed: {
    
    userFound() {
      const filteredUsers = this.allUsers.filter((item) => (
        item.name.toLowerCase().startsWith(this.searchUsers.toLowerCase())
      ));
      if (filteredUsers.length == 1 && this.showUsers) {
        return filteredUsers[0]
      }
      return null
    },
    filteredMarkers() {
      let sites = []
      if (this.showSites) {
        if (!this.searchSite) {
          sites = this.sites
        }

        sites = this.sites.filter((marker) => (
          marker.name.toLowerCase().startsWith(this.searchSite.toLowerCase())
          || marker.code.toLowerCase().startsWith(this.searchSite.toLowerCase())
        ));
      }
      if(this.showUsers) {
        const filteredUsers = this.allUsers.filter((item) => (
          item.name.toLowerCase().startsWith(this.searchUsers.toLowerCase())
        ));
        return [...sites, ...filteredUsers];
      }
      // if (this.showUsers) {
      // }
      // if (this.userFound) {
      //   return [...sites, this.userFound];
      // }
      return sites;
    },
    filteredSites() {
      let sites = []

      if (!this.searchSite) {
        sites = this.sites
      }

      sites = this.sites.filter((marker) => (
        marker.name.toLowerCase().startsWith(this.searchSite.toLowerCase())
        || marker.code.toLowerCase().startsWith(this.searchSite.toLowerCase())
      ));
      // sites.forEach((site) => {
      //   site.selected = false;
      // })

      return sites;
    }
  },
  watch: {
    filteredSites(newSites) {

      if (!this.selectedSite || !(newSites.some((element) => element.code == this.selectedSite.code))) {
        this.selectedSite = null
      }
      if (newSites.length == 1 && this.userFound && this.showUsers && this.showSites) {
        if (newSites[0].id != this.last) {
          this.last = newSites[0].id;
          this.onCalculateRoutes();
        }
      } else {
        this.onResetMap();
      }
    },
    userFound(newValue) {
      if(newValue && this.filteredSites.length == 1 && this.showSites) {
        if (this.filteredSites[0].id != this.last) {
          this.last = this.filteredSites[0].id;
          this.onCalculateRoutes();
        }
      } else {
        this.onResetMap();
      }
    }
  },
  methods: {
    async toogleShowUsers() {
      this.showUsers = !this.showUsers;
      await this.$nextTick();
      // if(this.showUsers) {
      //   this.$refs['multiselect'].value = this.users;
      // }
    },
    // changeTag() {
    //   const multi = this.$refs["multiselect"].value;
    //   this.users = multi
    // },
    // toogleSearch() {
    //   if(this.$refs['multiselect'].$refs['internal-multiselect'].$refs.search.readOnly) {
    //     this.$refs['multiselect'].$refs['internal-multiselect'].$refs.search.readOnly = false;
    //   }
    // },
    showRoute(route) {
      this.$toast.open({
        message: `Distance: ${route.distance}, Duration: ${route.duration}`,
        type: 'info',
      });
    },
    searchVal(val) {
      this.searchSite = val;
      this.selectedSite = null
      this.sites.forEach((site) => {
        site.selected = false;
      })
    },
    searchValUsers(val) {
      this.searchUsers = val;
      this.selectedSite = null
      this.allUsers.forEach((user) => {
        user.selected = false;
      })
    },
    calcClose() {
      const elements = document.getElementsByClassName('gm-ui-hover-effect');
      if (elements.length) {
        elements[0].addEventListener("click", () => {
          this.selectedSite = null;
          this.selectedUser = null
          this.sites.forEach((site) => {
            site.selected = false;
          })
          this.allUsers.forEach((user) => {
            user.selected = false;
          })
        })
      } else {
        this.selectedSite = null;
        this.selectedUser = null
        this.sites.forEach((site) => {
          site.selected = false;
        })
        this.allUsers.forEach((user) => {
          user.selected = false;
        })
      }
    },
    async onResetMap() {
      this.distances = [];
      this.last = null
      if (this.directionsRenderer) {

        this.reset = true;
        await this.$nextTick();
        this.reset = false;
        this.directionsRenderer = null;
      }
    },
    async calculateRoute(origin, destination, travelMode, site, user) {
      return new Promise((resolve) => {
        try {
          this.google = window.google;
          const request = {
            origin: origin,
            destination: destination,
            travelMode: travelMode,
          };
          const directionsService = new this.google.maps.DirectionsService();
          directionsService.route(request, (result, status) => {
            if (status === 'OK') {
              if (travelMode != 'WALKING') {
                this.directionsRenderer = new this.google.maps.DirectionsRenderer();

                const map = new this.google.maps.Map(document.getElementById('map'), {
                  center: this.center,
                  zoom: 10,
                });
                const route = result.routes[0].legs[0];
                const infoContentEnd = `
                  <div class="mt-4">
                    <div class="site-info-card">
                      <h4>${this.$t("Site Information")}</h4>
                      <p><strong>${this.$t("Code")}:</strong> ${site.code}</p>
                      <p><strong>${this.$t("Name")}:</strong> ${site.name}</p>
                      <p><strong>${this.$t("Direction")}:</strong> ${site.address}</p>
                      <p>
                        <strong>${this.$t("Team")}:</strong>
                        <span>${site.team ? site.team.team : this.$t("No data found")}</span>
                      </p>
                      <p>
                        <strong>${this.$t("Dispatcher")}:</strong>
                        <span>${site.team ? site.team.dispatcher : this.$t("No data found")}</span>
                      </p>
                      <div class="d-flex flex-column align-items-baseline">
                        <strong>${this.$t("Team Users")}:</strong>
                        <ul class="d-flex flex-column align-items-baseline">
                          ${site.team && site.team.users.length > 0
                    ? site.team.users.map(user => `
                              <li>
                                ${user.userFirstname} ${user.userLastname} - ${user.userName} (${user.phone})
                              </li>
                            `).join('')
                    : `<li>${this.$t("No data found")}</li>`
                  }
                        </ul>
                      </div>
                    </div>
                  </div>
                `;
                route.start_address = infoContentEnd
                const infoContentStart = `
                <div class="mt-4">
                  <div class="site-info-card">
                    <h4>${this.$t("User Information")}</h4>
                    <p><strong>${this.$t("Username")}:</strong> ${user.name}</p>
                    <p><strong>${this.$t("Name")}:</strong> ${user.user_first_name} ${user.user_lastname}</p>
                    <p><strong>${this.$t("Phone")}:</strong> ${user.phone}</p>
                  </div>
                </div>`;
                route.end_address = infoContentStart
                this.directionsRenderer.setMap(map);
                this.directionsRenderer.setDirections(result);
              } 
              const route = result.routes[0].legs[0];
              return resolve({
                distance: route.distance.text,
                duration: route.duration.text,
              })
              // Here set the distance for the route
              // this.$toast.open({
              //   message: `Distance: ${route.distance.text}, Duration: ${route.duration.text}`,
              //   type: 'info',
              // });
            } else {
              this.$toast.open({
                message: this.$t('Could not calculate route'),
                type: 'error',
              });
              return resolve(null)
            }
          });
        } catch (error) {
          this.$toast.open({
            message: this.$t('An error occurred while calculating the route'),
            type: 'error',
          });
        }
      })

    },
    async onCalculateRoutes() {
      this.distances = [];
      await this.$nextTick();
      const sites = this.sites.filter((marker) => (
        marker.name.toLowerCase().startsWith(this.searchSite.toLowerCase())
        || marker.code.toLowerCase().startsWith(this.searchSite.toLowerCase())
      ));
      if (sites.length == 1 && this.userFound) {
        const origin = sites[0].position;
        const destination = this.userFound.position;

        // Calcular ruta en vehículo
        const routeDriving = await this.calculateRoute(origin, destination, 'DRIVING', sites[0], this.userFound);
        let routeDrivingValue = null
        if (routeDriving) {
          routeDrivingValue = {
            type: 'DRIVING',
            ...routeDriving
          };
        }
        // // Calcular ruta caminando
        const routeWalking = await this.calculateRoute(origin, destination, 'WALKING', sites[0], this.userFound);
        let routeWalkingValue = null
        if (routeWalking) {
          routeWalkingValue = {
            type: 'WALKING',
            ...routeWalking
          };
        }
        this.distances = JSON.parse(JSON.stringify([
          routeDrivingValue,
          routeWalkingValue
        ]))
        this.selectedSite = sites[0]

      }
    },
    async getInitialData() {
      try {
        const { data: sitesData } = await getAllSites();
        const sites = sitesData.map((site) => ({
          id: site.id,
          name: site.site,
          code: site.code,
          selected: false,
          position: {
            lat: +site.latitude,
            lng: +site.longitude
          },
          team: site.team,
          address: site.address
        }));

        this.sites = sites
        if (sites.length) {
          this.center = sites[0].position
        }
        const { data: usersData } = await getAllLeadOperators();
        const users = usersData.map((user) => ({
          position: {
            lat: Number(user.latitude),
            lng: Number(user.longitude)
          },
          role_name: user.role_name,
          code: user.user_id,
          user_id: user.user_id,
          name: user.user_name,
          selected: false,
          address: user.address ?? '',
          user_first_name: user.user_first_name,
          phone: user.phone,
          user_lastname: user.user_lastname
        }))
        // this.users = users;
        this.allUsers = users;
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('An error has occurred'),
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async handleClickMarker(marker) {
      console.log(marker)
      await this.$nextTick();
      this.center = marker.position
      if(marker.user_id) {
        this.allUsers.forEach((user) => {
          user.selected = false;
        })
        this.selectedUser = marker;
        this.selectedUser.selected = true;
      }
      // if (this.userFound && marker.id === this.userFound.id) {
      //   this.userFound = {
      //     ...this.userFound,
      //     selected: !this.userFound.selected
      //   }
      //   return
      // }

      const currentSite = this.sites.filter((site) => {
        return site.id === marker.id
      })
      this.selectedSite = currentSite[0]

      this.sites = this.sites.map((m) => {
        if (m.id === marker.id) {
          return { ...m, selected: true }
        }
        return { ...m, selected: false }
      })
    },
    // async handleSearchUser(e) {
    //   try {
    //     this.userFound = null;
    //     const userName = e.target.value

    //     if (!userName) return

    //     this.searchUser = userName;
    //     const { data: user } = await getUserLocationByUserName({ userName });

    //     const userLat = user.position.lat;
    //     const userLng = user.position.lng;

    //     if (!!userLat && !!userLng) {
    //       this.center = {
    //         lat: userLat,
    //         lng: userLng,
    //       }
    //       this.userFound = user;
    //       this.onCalculateRoutes();
    //     } else {
    //       this.$toast.open({
    //         message: this.$t('User position not found'),
    //         type: 'info',
    //       })
    //     }
    //   } catch (error) {
    //     this.$toast.open({
    //       message: this.$t('User not found'),
    //       type: 'error',
    //     })
    //   }
    // }
  },
}
</script>

<style>
.map-container {
  padding: 0 1rem 1rem 1rem;
}

.route-card {
  background-color: #1c85d5;
  color: white;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.site-info-card {
  background-color: white;
  /* Fondo blanco para un look más sobrio */
  color: black;
  /* Texto negro */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column;
  align-items: baseline;
}

ul {
  padding-left: 20px;
}

.DRIVING {
  position: absolute;
  z-index: 1000;
  top: 46%;
  right: 1.3rem;
  cursor: pointer;
  background: white;
  padding: 0.6rem;
}

.WALKING {
  position: absolute;
  z-index: 1000;
  top: 34%;
  right: 1.3rem;
  cursor: pointer;
  background: white;
  padding: 0.6rem;
}
</style>