<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Files (nav)')" />
      <!-- <div class="container"> -->
        <div class="container background-form my-3 p-3">
        <PageTitle
          :title="$t('Assignments')"
          :description="$t('Go to Active assignments')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Assignment Management')"
          :grandChildBread="$t('Assignments')"
        />
        <!-- Search filters -->
        <div class="row my-3">
          <div class="col-12 col-lg-4">
            <rd-input
              type="text"
              :label="$t('Search an assignment')"
              :placeholder="$t('Search assignments by')"
              clearIcon
              @clearIcon="clearSearch"
              @input="searchBar($event)"
            />
          </div>
        </div>

        <!-- Assignments -->
        <!-- loading -->
        <div v-if="assingmentsLoading" class="mt-5">
          <rd-loading />
        </div>

        <!-- after loading -->
        <div v-if="!assingmentsLoading">
          <div
            v-if="filteredAssigments.length === 0"
            class="row justify-content-center"
          >
            <div class="col-12 text-center mt-4">
              <img
                src="@/assets/imgs/exclamation-triangle-fill.svg"
                height="150"
              />
            </div>
            <div class="col-12 fs-3 fw-bold text-center">
              {{ $t("No information available") }}
            </div>
          </div>
          <div v-else-if="!showSearchResults" class="row my-2 text-left">
            <div class="row">
              <div class="col-12 fs-3 fw-bold mx-2 text-primary">
                {{ $t("Active Assignments") }} - {{ allsitesPartialCompleted }}
                {{
                  allsitesPartialCompleted > 1
                    ? $t("Assignments")
                    : $t("Assignment")
                }}
              </div>
            </div>

            <div class="accordion">
              <div class="accordion-item" v-for="(item, index) in filteredAssigments" :key="index">
                <h2 class="accordion-header" :id="`heading-${index}`" style="position: relative;">
                  <button
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse-${index}`"
                    :aria-controls="`collapse-${index}`"
                    aria-expanded="true"
                    class="accordion-button fs-3 accordion-left"
                    type="button"
                    style="display: flex; flex-flow: row-reverse; justify-content: flex-end; gap: 1rem; position: relative; z-index: 1;"
                  >
                    {{ item.assignementsDate }} -
                    {{ item.sitesPartialCompleted }}
                    {{ item.sitesPartialCompleted > 1 ? $t("Assignments") : $t("Assignment") }}
                  </button>
                  <!-- v-if="permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS) || user.isAdmin || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS)" -->
                  <div 
                    v-if="user.isAdmin || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS)"
                    class="toggle-button-container"
                    style="position: absolute; right: 1rem; top: 50%; transform: translateY(-50%); z-index: 2;"
                  >
                    <button
                      @click.stop.prevent="toggleAvailability($event, item)"
                      class="btn btn-sm btn-label"
                      style="background: none; border: none;"
                    >
                      <i v-if="item.is_available" class="rem2 bi bi-toggle-on"></i>
                      <i v-else class="rem2 bi bi-toggle-off"></i>
                    </button>
                  </div>
                </h2>
                <div
                  :id="`collapse-${index}`"
                  class="accordion-collapse collapse"
                  :class="{ show: false }"
                  :aria-labelledby="`heading-${index}`"
                >
                  <div class="accordion-body">
                    <div class="accordion col-12">
                      <active-assignment-component
                        v-for="(assignment, indexAssignments) in item.assignments"
                        :key="indexAssignments"
                        :assignment="{ ...assignment, index: indexAssignments }"
                        class="mb-1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="showSearchResults">
            <div class="accordion">
              <active-assignment-component
                v-for="(assignment, index) in filteredAssigments"
                :key="index"
                :assignment="{ ...assignment, index }"
                class="mb-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import ActiveAssignmentComponent from "@/views/assignments/active-assignment-component.vue";
import { getActiveAssignments } from "@/services/assignments/assignment.api";
import RdInput from "../../components/rd-components/rd-input.vue";
import { mapState } from "vuex";
import { convertDateFormat2 } from "../../utils/convertFormat";
import RdLoading from "../../components/rd-components/rd-loading.vue";

import { assignmentAvailabilityByDate } from "../../services/assignments/assignment.api";
import rdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import {APP_PERMISSIONS} from '@/utils/constants.js'
import PageTitle from "../../components/rd-components/page-title.vue";

export default {
  data() {
    return {
      userConfirmed: [],
      userListedToBan: [],
      usersNotBanned: [],
      assignmentsDateFormattedFromDB: [],
      filteredAssigments: [],
      assignementsWithoutDateFormat: [],
      showSearchResults: false,
      searchInput: null,
      downloadingReportsLoading: false,
      showCalendar: false,
      callback: null,
      fecha: null,
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      day: new Date().getDate(),
      months: [
        this.$t("ENERO"),
        this.$t("FEBRERO"),
        this.$t("MARZO"),
        this.$t("ABRIL"),
        this.$t("MAYO"),
        this.$t("JUNIO"),
        this.$t("JULIO"),
        this.$t("AGOSTO"),
        this.$t("SEPTIEMBRE"),
        this.$t("OCTUBRE"),
        this.$t("NOVIEMBRE"),
        this.$t("DICIEMBRE"),
      ],
      dates: [],
      allsitesPartialCompleted: 0,
      assingmentsLoading: true,
      APP_PERMISSIONS
    };
  },
  components: { ActiveAssignmentComponent, RdInput, RdLoading, rdFooter, RdNavbar, PageTitle },
  computed: {
    ...mapState(["mobileDisplay", "permissions", "user"]),
    user() {
      return this.$store.state.user;
    },
    monthDisplayed() {
      return "";
    },
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    await this.getAllUserAssignments();
    this.fecha = `${this.months[this.month]}/${this.day}/${this.year}`;
  },
  beforeUnmount() {
    window.removeEventListener("click", this.callback);
  },
  methods: {
    async toggleAvailability(e, data) {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      const { assignementsDate, is_available } = data;
      try {
        await assignmentAvailabilityByDate({
          assignementsDate,
          is_available: !is_available,
        });
        await this.processAssignments();
        this.filteredAssigments = this.assignmentsDateFormattedFromDB;
      } catch (error) {
        console.log(error);
      }
    },
    calcPartialCompletedSites(item) {
      let sitesPartialCompleted = 0;
      item.assignments.forEach((element) => {
        if (
          element.sites[0].statusTeamId == 2 ||
          element.sites[0].statusTeamId == 3
        ) {
          sitesPartialCompleted += 1;
        }
      });
      return sitesPartialCompleted;
    },
    hideBanModal() {
      this.userListedToBan = JSON.parse(JSON.stringify(this.userConfirmed));

      this.$refs.hideModalBan.click();
    },
    addUsers(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.userListedToBan.push(tag);
      this.usersNotBanned.push(tag);
    },

    goToMassiveLoad() {
      this.$router.push("/admin/massive-loads");
    },
    goToEditSite() {
      this.$router.push("/admin/sites");
    },
    hideConfirmationModal() {
      this.$refs.hideModal.click();
    },
    async processAssignments() {
      const { data } = await getActiveAssignments();
      this.assignementsWithoutDateFormat = data.assignements;

      // get assignment dates
      const assignmentsDates = [
        ...new Set(data.assignements.map((element) => element.activityDate)),
      ];

      // create object assignments => dates
      const assignementsByDate = assignmentsDates.map((element) => {
        const assignments = data.assignements.filter(
          (assignm) => assignm.activityDate === element
        );

        let assignmentsByHour = assignments;
        const assignmentIsAvailable = assignments.every(
          (assignment) => assignment.isAvailable
        );

        if (assignments.length > 1) {
          assignmentsByHour = assignments.sort((a, b) => {
            const [hourOne, minutesOne] = a.sites[0].checkIn
              ? a.sites[0].checkIn.split(":")
              : ["00", "00"];
            const [hourTwo, minutesTwo] = b.sites[0].checkIn
              ? b.sites[0].checkIn.split(":")
              : ["00", "00"];
            return hourOne - hourTwo || minutesOne - minutesTwo;
          });
        }

        return {
          assignementsDate: element,
          assignments: assignmentsByHour,
          is_available: assignmentIsAvailable,
        };
      });

      // assign result
      this.assignmentsDateFormattedFromDB = assignementsByDate.sort((a, b) => {
        return (
          convertDateFormat2(b.assignementsDate) -
          convertDateFormat2(a.assignementsDate)
        );
      });
      this.filteredAssigments = this.assignmentsDateFormattedFromDB;
      let allsitesPartialCompleted = 0;
      this.filteredAssigments.forEach((element) => {
        const sitesPartialCompleted = this.calcPartialCompletedSites(element);
        allsitesPartialCompleted += sitesPartialCompleted;
        element.sitesPartialCompleted = sitesPartialCompleted;
      });
      this.allsitesPartialCompleted = allsitesPartialCompleted;
    },
    async getAllUserAssignments() {
      try {
        this.assingmentsLoading = true;
        await this.processAssignments();
      } catch (error) {
        console.log(error);
      } finally {
        this.assingmentsLoading = false;
      }
    },
    handleRouting(route) {
      this.$router.push(route);
    },
    searchBar(event) {
      // searching by activity date, hour and code
      const filter = event.target.value.toLowerCase();

      if (filter.length === 0) {
        this.filteredAssigments = this.assignmentsDateFormattedFromDB;
        this.showSearchResults = false;
        return;
      }
      const tempAssignments = JSON.parse(JSON.stringify(this.assignementsWithoutDateFormat))
      this.filteredAssigments = tempAssignments.filter(
        (element) => {
          return (
            element.activityDate.toLowerCase().startsWith(filter) ||
            // element.sites[0].checkIn.toLowerCase().startsWith(filter) ||
            element.sites[0].code.toLowerCase().startsWith(filter) ||
            element.team.users.some((user) => user.userFirstname?.toLowerCase().startsWith(filter) || user.userLastname?.toLowerCase().startsWith(filter))
          );
        }
      );
      this.filteredAssigments.forEach((element) => {
        if(!(
          element.activityDate.toLowerCase().startsWith(filter) || 
          // element.sites[0].checkIn.toLowerCase().startsWith(filter) || 
          element.sites[0].code.toLowerCase().startsWith(filter)
        )) {
          element.team.users = element.team.users.filter(
            (user) => {
              return user.userFirstname?.toLowerCase().startsWith(filter) || user.userLastname?.toLowerCase().startsWith(filter)
            }
          )
        }
      })
      this.showSearchResults = true;
    },
    clearSearch() {
      this.filteredAssigments = this.assignmentsDateFormattedFromDB;
      this.showSearchResults = false;
    },
  },
};
</script>

<style lang="scss">
.calendar-container {
  position: relative !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  input {
    border: 1px solid rgba(0, 0, 0, 0.175) !important;
    border-radius: 0.375rem !important;
    padding-left: 1rem !important;
  }
  .calendar {
    width: auto !important;
    margin-top: 1rem !important;
    font-family: Roboto !important;
    position: absolute !important;
    z-index: var(--top-layer-z-index) !important;
    background-color: #fafafa !important;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-around !important;
    border: 1px solid rgba(0, 0, 0, 0.175) !important;
    border-radius: 0.375rem !important;
    padding-top: 1rem !important;
    h1 {
      color: #3cd0fb !important;
      font-size: 1rem !important;
      text-align: center !important;
    }
    strong {
      h1 {
        color: #2387c6 !important;
      }
    }
    .days {
      background: #aaaaa9 !important;
      color: white !important;
    }
    ul {
      display: grid !important;
      grid-template-columns: repeat(7, 1fr) !important;
      list-style: none !important;
      gap: 0.5rem !important;
      .prev-month {
        color: transparent !important;
      }
      .prev-day {
        opacity: 0.5 !important;
      }
    }
  }
}
.accordion-button-custom {
  padding-left: 0 !important;
}
.accordion-button-custom::after {
  display: none !important;
}
.accordion-button-custom::before {
  margin: 0 0.5rem 0 !important;
  flex-shrink: 0 !important;
  width: var(--bs-accordion-btn-icon-width) !important;
  height: var(--bs-accordion-btn-icon-width) !important;
  content: "" !important;
  background-image: var(--bs-accordion-btn-icon) !important;
  background-repeat: no-repeat !important;
  background-size: var(--bs-accordion-btn-icon-width) !important;
  transition: var(--bs-accordion-btn-icon-transition) !important;
  transform: rotate(0.75turn) !important;
}
.accordion-button-custom:not(.collapsed)::before {
  transform: rotate(0turn) !important;
}
.accordion-button-custom:not(.collapsed) {
  background-color: transparent !important;
  color: black !important;
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 black;
}
.accordion-item-custom {
  border: none;
}
.rem2 {
  font-size: 2rem;
}

// Custom style for buttons
.accordion-header {
  position: relative;
}

.toggle-button-container {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2; /* Ensure it's above the parent button */
  pointer-events: auto; /* Ensure the button is clickable */
}

.accordion-button {
  z-index: 1; /* Ensure it's below the inner button */
}

.toggle-button-container button {
  background: none;
  border: none;
}

</style>