<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Edit Assignment (nav)')" />
      <div class="container text-left">
        <div class="row">
          <h4 class="col-12 text-capitalize">
            {{ $t("edit assignment") }}
          </h4>
        </div>

        <div class="row">
          <div class="col-12">
            <span class="fw-bold">{{ $t("Site info") }}: </span
            >{{ siteInfo.site }} ({{ siteInfo.siteCode }}) - {{ siteInfo.address }} - {{ siteInfo.city }}
          </div>
          <div class="col-12">
            <span class="fw-bold">{{ $t("Team") }}: </span
            >{{ siteInfo.teamName }}
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-lg-6">
            <rd-multiselect
              ref="multiselect"
              :initialValue="usersInThisAssignment"
              :placeholderToTag="$t('Add users to this team')"
              :placeholder="$t('Users available')"
              :label="$t('Add users to this team')"
              name="name"
              :optionsToSelect="usersToAddToTeam"
              @handleTagChange="changeTag"
              @click="toogleSearch"
            />
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-lg-6">
            <ul class="list-group shadow">
              <li
                class="list-group-item list-group-item-action fs-5 fw-bolder text-center"
              >
                {{ $t("Team members") }}
              </li>
              <li
                class="list-group-item list-group-item-action fs-6 cursor-pointer"
                v-for="(user, index) in usersToRender"
                :key="index"
                @click="showUserDetails({ userId: user.userId })"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    {{ user.userFirstname }} {{ user.userLastname }}
                    <span class="fw-bold">
                      <!-- TODO: Add check to function -->
                      ({{
                        user.function == 1
                          ? $t("Lead")
                          : user.function == 2
                          ? $t("Operator")
                          : ""
                      }})
                    </span>
                  </div>
                  <button-component
                    v-if="permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.PROMOTE_DEMOTE) || loggedUser.isAdmin"
                    @handleClick="changeFunction(user)"
                    :primary="user.function == 2"
                    :danger="user.function == 1"
                    class="btn"
                  >
                    {{ user.function == 1 ? 'Demote' : 'Promote' }}
                  </button-component>
                </div>
                {{ $t("Contact number") }}:
                <span class="fw-bold">
                  {{ user.phone }} - {{ user.city }}
                </span>
                <br />
                <span v-if="user.state_task.length">
                  {{ `Reason for rejection: ` }}
                  <span class="fw-bold">{{
                    user.state_task[0].observations
                  }}</span>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Buttons -->

        <div class="row my-3">
          <div class="col-6 col-lg-3">
            <button-component
              @handleClick="goBack"
              primaryOutline
            >
              {{ $t("Dismiss") }}
            </button-component>
          </div>
          <div class="col-6 col-lg-3 text-end">
            <button-component
              :disabled="disableSaveButton"
              @handleClick="handleEditThisAssignment"
              primary
            >
              {{ $t("Save edit assignment") }}
            </button-component>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>

    <!-- user details modal -->
    <button
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"
      type="button"
      class="d-none"
      ref="userDetailsBtn"
    ></button>
    <user-details-component
      id="offcanvasRight"
      :userId="userSelectedId"
      @hide="userSelectedId = null"
    />
  </div>
</template>
<script>
import {
  getAssignmentById,
  updateAssignmentById,
} from "../../services/assignments/assignment.api";
import { getAllUsersLeadOperator, changeUserFunction } from "../../services/user/user.api";
import { getTeamById, updateTeam } from "../../services/team/team.api";
import RdMultiselect from "../../components/rd-components/rd-multiselect.vue";
import { APP_PERMISSIONS } from "@/utils/constants.js";
import rdFooter from "@/components/rd-components/rd-footer.vue";
import RdNavbar from "@/components/rd-components/rd-navbar.vue";
import ButtonComponent from '../../components/button-component.vue';
import UserDetailsComponent from '../../components/smarthire/user-details-component.vue';
import { mapState } from "vuex";

export default {
  data() {
    return {
      initialDate: null,
      teamId: null,
      usersInThisAssignment: [],
      usersToAddToTeam: [],
      usersToRender: [],
      siteInfo: {},
      fileUrl: "",
      disableSaveButton: false,
      APP_PERMISSIONS,
      userSelectedId: null,
      allUsers: []
    };
  },
  async mounted() {
    await this.getThisAssignmentData();
    this.$watch(
      () => {
        return [this.$refs["multiselect"].value];
      },
      ([multiselect]) => {
        this.disableSaveButton = multiselect.length === 0;
      }
    );
  },
  computed: {
    ...mapState(["permissions"]),
    loggedUser() {
      return this.$store.getters.getUser;
    },
    assignmentId() {
      return this.$route.params.id;
    },
  },
  components: { RdMultiselect, rdFooter, RdNavbar, ButtonComponent, UserDetailsComponent },
  methods: {
    toogleSearch() {
      if(this.$refs['multiselect'].$refs['internal-multiselect'].$refs.search.readOnly) {
        this.$refs['multiselect'].$refs['internal-multiselect'].$refs.search.readOnly = false;
      }
    },
    showUserDetails({ userId }) {
      this.userSelectedId = +(userId)
      this.$refs.userDetailsBtn.click()
    },
    async changeFunction(user) {
      try {
        await changeUserFunction(user.userId);

        this.$toast.open({
          message: "User Changed Successfully",
          type: "success",
        });

        this.usersToRender.forEach((internal) => {
          if(user.userId == internal.userId) {
            if(internal.function == 1) {
              internal.function = 2;
            } else {
              internal.function = 1;
            }
          }
        })
        this.allUsers.forEach((internal) => {
          if(user.userId == internal.user_id) {
            if(internal.function == 1) {
              internal.function = 2;
            } else {
              internal.function = 1;
            }
          }
        })
        const availableUsers = this.allUsers.map((user) => ({
          code: user.user_id,
          name: `${user.user_name} - (${
            user.function == 1 ? "Lead" : "Operator"
          })`,
        }));
        this.usersToAddToTeam = availableUsers;
        this.usersInThisAssignment = this.usersToRender.map((user) => ({
          code: user.userId,
          name: `${user.userName} - (${
            user.function == 1 ? "Lead" : "Operator"
          })`,
        }));
      } catch (error) {
        this.$toast.open({
          message: 'Error changing user',
          type: "error",
        });
        console.log(error);
      }
    },
    async changeTag() {
      await this.$nextTick(() => {
        const multi = this.$refs["multiselect"].value;
        
        const users = this.allUsers.filter(user => multi.some(element => element.code == user.user_id))
        this.usersToRender = users.map(user => {
          return {
            userId: user.user_id,
            userFirstname: user.user_first_name,
            userLastname: user.user_lastname,
            function: user.function,
            phone: user.phone,
            userName: user.user_name,
            state_task: user.state_task,
            city: user.city
          }
        })
      });
    },
    async handleEditThisAssignment() {
      try {
        // update assignment date
        const dataToUpdate = {
          file_url: this.fileUrl,
        };

        await updateAssignmentById(this.assignmentId, dataToUpdate);

        // editing team
        const team = {
          teamId: this.teamId,
          body: {
            users: this.$refs["multiselect"].value.map(
              (user) => `${user.code}`
            ),
            siteId: this.siteInfo.id,
          },
        };
        await updateTeam(team);

        this.$toast.open({
          message: "Assignment Updated Successfully",
          type: "success",
        });

        this.$router.back();
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: "error",
        });
        console.log(error);
      }
    },
    async getThisAssignmentData() {
      try {
        // getting this assignment data
        const assginment = await getAssignmentById(this.$route.params.id);
        this.siteInfo.site = assginment.data.sites[0].site;
        this.siteInfo.id = assginment.data.sites[0].id;
        this.siteInfo.siteCode = assginment.data.sites[0].code;
        this.siteInfo.address = assginment.data.sites[0].address;
        this.siteInfo.teamName = assginment.data.team.team;
        this.siteInfo.city = assginment.data.sites[0].region.region;

        // set initial values for assignment
        this.initialDate = assginment.data.activityDate;
        this.fileUrl = assginment.data.fileUrl;

        // getting this assginment team data
        this.teamId = assginment.data.teamId;
        let users = await getAllUsersLeadOperator(this.$route.params.id);
        this.allUsers = users.data;

        // filtrando a los usuarios que no estan en este equipo
        // TODO: Add check to function
        const availableUsers = users.data.map((user) => ({
          code: user.user_id,
          name: `${user.user_name} - (${
            user.function == 1 ? "Lead" : "Operator"
          })`,
        }));
        this.usersToAddToTeam = availableUsers;
        const team = await getTeamById(this.teamId, this.$route.params.id);
        this.usersToRender = team.data.users;
        this.usersInThisAssignment = team.data.users.map((user) => ({
          code: user.userId,
          name: `${user.userName} - (${
            user.function == 1 ? "Lead" : "Operator"
          })`,
        }));

        // getting all users
        
      } catch (error) {
        console.log(error);
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.min-vh-100 {
  min-height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}

.list-group-item {
  z-index: var(--ground-layer-z-index);
}
</style>
